export default [
  {
    path: '/licences',
    name: 'licenses',
    component: () => import('@/views/licence/LicensesList.vue'),
    meta: {
      resource: 'licences',
      action: 'manage',
    },
  },
  {
    path: '/broadcasting/licenses',
    name: 'licenses-broadcasting',
    component: () => import('@/views/licence/BroadcastingLicensesList.vue'),
    meta: {
      resource: 'licences',
      action: 'manage',
    },
  },
  {
    path: '/psv/licences',
    name: 'licenses-psv',
    component: () => import('@/views/licence/PsvLicensesList.vue'),
    meta: {
      resource: 'licences',
      action: 'manage',
    },
  },
  {
    path: '/licence/:id',
    name: 'licence-view',
    component: () => import('@/views/licence/LicenseView.vue'),
    meta: {
      resource: 'licences',
      action: 'manage',
    },
  },
  {
    path: '/licence/:id/edit',
    name: 'licence-edit',
    component: () => import('@/views/licence/EditLicense.vue'),
    meta: {
      resource: 'licences',
      action: 'manage',
    },
  },
  {
    path: '/license/new/:type?',
    name: 'license-new',
    component: () => import('@/views/licence/LicenseApplicationForm.vue'),
    meta: {
      resource: 'licences',
      action: 'manage',
    },
  },
  {
    path: '/entities',
    name: 'entities',
    component: () => import('@/views/business/BusinessList.vue'),
    meta: {
      resource: 'licences',
      action: 'manage',
    },
  },
  {
    path: '/entity/:id',
    name: 'entity-view',
    component: () => import('@/views/business/BusinessView.vue'),
    meta: {
      resource: 'licences',
      action: 'manage',
    },
  },
  {
    path: '/invoices',
    name: 'invoices',
    component: () => import('@/views/invoice/InvoiceList.vue'),
    meta: {
      resource: 'licences',
      action: 'manage',
    },
  },
  {
    path: '/invoice/:id',
    name: 'invoice-view',
    component: () => import('@/views/invoice/InvoicePreview.vue'),
    meta: {
      resource: 'licences',
      action: 'manage',
    },
  },
  {
    path: '/invoice/:id/pay-method',
    name: 'invoice-select-pay-method',
    component: () => import('@/views/invoice/InvoiceSelectPaymentMethod.vue'),
    meta: {
      resource: 'licences',
      action: 'manage',
    },
  },
  {
    path: '/invoice/:id/pay',
    name: 'invoice-pay',
    component: () => import('@/views/invoice/InvoiceMpesaPayment.vue'),
    meta: {
      resource: 'licences',
      action: 'manage',
    },
  },
  {
    path: '/invoice/:id/pay/confirm',
    name: 'invoice-pay-confirm',
    component: () => import('@/views/invoice/InvoicePaymentConfirm.vue'),
    meta: {
      resource: 'licences',
      action: 'manage',
    },
  },
  {
    path: '/invoice/:id/cheque/pay',
    name: 'invoice-cheque-view',
    component: () => import('@/views/invoice/InvoiceChequePayment.vue'),
    meta: {
      resource: 'licences',
      action: 'manage',
    },
  },
]
